$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.tableTitleCell {
    width: 215px;
    font-weight: $bold-weight;
    color: $darkBlue;
    margin-bottom: 4px;
}

.tableTitleCellMedium {
    width: 500px;
    font-weight: $bold-weight;
    color: $darkBlue;
    margin-bottom: 4px;
}

.info {
    margin-top: 12px;
    color: $grey-400;
}

.experienceInfo {
    flex: 1;
    color: $grey-100;
    font-weight: $regular-weight;
    margin-bottom: 4px;
    white-space: pre;
}

.comment {
    margin-top: 20px;
    color: #5B6880;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.greenLink {
    color: #17B67C;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-decoration-line: underline;
}
@media (max-width: 1000px) {
    .tableTitleCell {
        width: 100%;
    }

    .tableTitleCellMedium {
        width: 300px;
        p {
            word-wrap: break-word;
        }
    }
    .comment {
        width: 300px;
        p {
            word-wrap: break-word;
        }
    }
}