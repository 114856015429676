$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.active {
	background: $white;
	border: 1px solid $light-grey-200;
	padding: 11px 20px;
	border-radius: 4px;
	width: 100%;
	font-size: 12px;
	color: $whiteBlue;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	max-height: 44px;
	overflow: hidden;
}

.arrow {
	width: 12px;
}

.list {
	background: $white;
	padding: 16px 8px;
	border-radius: 4px;
	margin-top: 10px;
	position: absolute;
	width: 100%;
	.item {
		display: flex;
		grid-column-gap: 6px;
		align-items: center;
		font-size: 12px;
		font-weight: $middle-weight;
		color: $grey-100;
	}

	.actions {
		display: flex;
		justify-content: space-evenly;
		grid-column-gap: 16px;
		margin-top: 16px;
	}

	&:before {
		content: '';
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 10px 15px 10px 0;
		border-color: transparent $white transparent transparent;
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
		position: absolute;
		right: 6px;
		top: -12px;
		transform: rotate(89deg);
	}
}
