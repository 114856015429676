$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.formBtn {
  display: flex;
  flex-direction: row;
}

.inputFile {
  margin-right: 0;
  input {
    width: 0;
    height: 0;
    opacity: 0;
  }
  .customFile {
    background: $green;
    cursor: pointer;
    align-items: center;
    padding: 11px 22px;
    border-radius: 2px;
    transition: 0.2s;
    img {
      margin-right: 8px;
    }
    span {
      font-weight: $middle-weight;
      font-size: 14px;
      line-height: 110% !important;
      margin: 0 !important;
      padding: 0 !important;
      color: $white;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}

.buttonPanel {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.outerDiv {
  justify-content: space-between;
}

.innerDiv {
  display: flex;
  .TreeViewProjectContainer,
  .TablePositionContainer{
    flex: 1; // Здесь '1' означает "растянуть на все доступное пространство по основной оси (в данном случае, горизонтально)"
  }
  .divider {
    width: 1.5px; // Ограничиваем ширину линии разделителя
  }
}

.divider {
  border-left: 1.5px solid $black;
  height: 100vh;
  margin: 0 15px;
}

.countStaff {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: $grey-100;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 8px;

    span {
      color: $blue;
    }
  }
}

.ml15 {
  margin-left: 15px;
}
