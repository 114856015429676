$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  margin-top: 44px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.row {
  display: flex;
  gap: 32px;
}

.datePicker {
  flex: 1;
  label {
    font-weight: $bold-weight;
    color: $darkBlue;
    display: block;
    margin-bottom: 4px;
  }
}

.label {
  font-weight: $bold-weight;
  color: $darkBlue;
  display: block;
  margin-bottom: 8px;
}

.hintIcon {
  fill: $green;
  margin-left: 8px;
}

.selectCol {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.button {
  width: 130px;
  align-items: center;

  &Red {
    @extend .button;
    color: #EB5757;
  }
}

.icon {

  width: 12px;
  height: 12px;
  margin-right: 8px;
  &Green {
    @extend .icon;
    fill: $green;
  }

  &Red {
    @extend .icon;
    fill: #EB5757;
  }
}

.field {
  font-weight: $bold-weight;
  color: $darkBlue;
  width: 273px;
  height: 16px;
  margin-right: 32px;
  margin-bottom: 5px;
}

.error {
  color: #E65332
}

.answer {
  margin-top: 44px;
  font-weight: $bold-weight;
  color: $darkBlue;
  margin-bottom: 32px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 60px;
}

.cancel__button {
  font-size: 16px;
  line-height: 110%;
  color: $whiteBlue;
  cursor: pointer;
}

.cancel__view {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 20px;
}

.tableTitleCell {
  width: 215px;
  font-weight: $bold-weight;
  color: $darkBlue;
  margin-bottom: 4px;
}

@media (max-width: 1000px) {
  .shynar, .tableTitleCell {
    width: 100%;
  }
}