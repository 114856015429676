.form {
	display: grid;
	grid-row-gap: 56px;
	margin-top: 44px;
	width: 100%;
}

.item {
	display: grid;
	grid-row-gap: 24px;
}

.fields {
	display: grid;
	grid-column-gap: 32px;
	grid-template-columns: 1fr 2fr;
	align-items: flex-start;

	&_verticalCenter {
		align-items: center;
	}
}

.fieldsFullWidth {
	display: grid;
	grid-column-gap: 32px;
	grid-template-columns: 3fr;
}

.fieldAndButton {
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-column-gap: 32px;
	align-items: flex-start;

	Button {
		margin-top: 2rem;
	}
}

.threeFields {
	grid-template-columns: 32.5% 30.7% 30.6%;
}

.actions {
	display: flex;
	justify-content: flex-end;
	grid-column-gap: 60px;
}

.actions_electronic {
	justify-content: flex-start;
}

.wrapper{
	position: relative;
}

.label {

	label {
		font-size: 16px;
		line-height: 120%;
	}
}

.labelHint {
	display: flex;
	label {
		font-size: 16px;
		line-height: 120%;
		margin-right: 0 !important;
	}
}

.error {
	color: #E65332;
	margin-top: 10px;
}

.hintIcon {
	fill: #17b67c;
	margin-left: 8px;
}