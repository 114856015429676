$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.labelStatus {
  margin-top: 20px;
  color: var(--Secondary, $darkBlue);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  label {
    color: var(--Secondary-Text, $grey-100);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}

.fieldAndButton {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 32px;
  align-items: flex-start;

  Button {
    margin-top: 2rem;
  }
}

.loader {
  position: absolute;
  left: 230px;
  bottom: 30px;
}