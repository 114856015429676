$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.form {
	label {
		margin-bottom: 8px;
	}
}

.row {
	display: flex;
	gap: 32px;
}

.col {
	display: flex;
	flex-direction: column;
	gap: 24px;

	&1 {
		@extend .col;
		margin-bottom: 64px;
	}
}

.title {
	font-size: 18px;
	font-weight: 400;
}

.conditionSection {
	margin-bottom: 32px;
}

.footer {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
}

.dataCol {
	width: 170px;
	margin-bottom: 4px;
	font-weight: 700;
}

.dataCol300 {
	min-width: 290px;
	margin-bottom: 4px;
	font-weight: 700;
}

.greyText {
	color: $grey-100;
	margin-bottom: 4px;
}

.mainInfoRow {
	margin-bottom: 60px;
	margin-top: 27px;
}

.checkbox__wrapper {
	margin-bottom: 10px;
	label {
		margin-bottom: 0 !important;
		display: flex !important;
		color: #5B6880;
	}
	span {
		opacity: 0.5;
	}
}

.error {
	color: #E65332;
}

.radioLabel {
	margin-bottom: 25px;
	margin-top: 10px;
	label:before {
		top: 1px;
	}
	label div {
		margin-left: 15px;
	}
	label.marginLeft0 div {
		margin-left: 0;
	}
}

.fields {
	display: grid;
	grid-column-gap: 32px;
	grid-template-columns: 1fr 2fr;
	align-items: flex-start;
	margin-bottom: 44px;

	&_verticalCenter {
		align-items: center;
	}
}

.threeFields {
	grid-template-columns: 32.5% 30.7% 30.6%;
}

.labelHint {
	display: flex;
	label {
		font-size: 16px;
		line-height: 120%;
		margin-right: 0 !important;
	}
}

.hintIcon {
	fill: #17b67c;
	margin-left: 8px;
}