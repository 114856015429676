$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.table__container {
  margin-top: 12px;
  border: 1px solid $light-grey-200;
  border-radius: 8px 8px 0 0;
  max-height: 55vh;
}

.table__head__row {
  background-color: #5B6880 !important;
  color: white !important;
  padding: 10px !important;
  font-family: Inter, sans-serif !important;
  font-weight: bold !important;
  font-style: normal;
  height: 40px;
  &:first-child {
    border-left: 1px solid $light-grey-200 !important;
    border-radius: 8px 0 0 0 !important;
  }

  &:last-child {
    border-right: 1px solid $light-grey-200 !important;
    border-radius: 0 8px 0 0 !important;
  }
}

.table__body_cell {
  padding: 11px 0 11px 11px !important;
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: $darkBlue !important;
  &:hover {
    cursor: pointer;
  }
}

.messageCol {
  height: 200px;
  text-align: center;
  width: 40vw;
}

.tableLoading {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* Secondary Text */
  color: #5b6880;
  width: 40vw;
}

.isLastRowMenuOpen {
  overflow: visible !important;
}