$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  &__top {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: #5B6880;
    margin-top: 21px;
  }
  &__date {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: #5B6880;
    margin-right: 10px;
  }
  &__mLeft {
    margin-left: 20px;
    color: #5B6880;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    label {
      font-size: 14px;
      font-weight: 700;
      line-height: 15px;
      color: #1D293F;
    }
  }
  &__mb10 {
    margin-bottom: 10px;
  }
}

.hint__info {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  color: #5B6880;
  margin-bottom: 7px;
}