$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  img {
    height: 339px;
    position: relative;
    border-radius: 0 24px 24px 0;
  }
}

.text {
  display: flex;
  flex-direction: column;
  max-height: 339px;
  height: 100%;
}

.textTop {
  margin-bottom: 40px;
  margin-left: 40px;
  p {
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $black;
    margin-bottom: 5px;
  }
  span {
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $green;
  }
}

.textButton {
  margin-left: 40px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $grey-500;
  margin-bottom: 5px;
}

.button {
  margin-top: 20px;
  margin-left: 40px;
}

@media screen and (max-width: 700px) {
  .wrapper {
    height: 380px;
    img {
      display: none;
    }
  }
  .textTop {
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .textButton {
    margin-left: 20px;
  }
  .button {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}