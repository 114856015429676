$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.form {
  padding: 29.81px 27.42px;
  input,
  textarea {
    :focus {
      outline: auto;
    }
  }

  .title {
    font-family: 'Inter Bold', sans-serif;
    font-style: normal;
    text-align: left;
    font-size: 32px;
    line-height: 120%;
    color: #1D293F;
    margin-bottom: 7.85px;
  }

  p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    color: #1D293F;
  }

  .inputStyle {
    textarea {
      padding-top: 16px;
      min-height: 110px;
        }
    input,
    textarea {
      width: 350px;
      height: 40px;
      margin-bottom: 16px;
      border-radius: 4px;
      background: #F9FBFF;
      border: 1px solid #D3DFF0;
      padding-left: 14px;
      color: rgba(29, 41, 63, 1);
      outline-color: rgba(29, 41, 63, 1);
      box-sizing: border-box;
      &::placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0;
        text-align: left;
        color: #AAB8D1;
      }
    }
  }

  .formBtn {
    display: flex;
    flex-direction: row;
          & > * {
            margin-right: 15px;
          }
  }

  .greenBtn {
    width: 184px;
    display: flex;
    flex-direction: row;
  }

  label {
    margin-right: 0;
  }
}

.selectIcon {
  top: 20px;
}

@media screen and (max-width: 374px){
  .form {
    .inputStyle {
      input,
      textarea {
        width: 200px;
      }
    }
  }
}