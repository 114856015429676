$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.textarea {
  border: 1px solid $light-grey-200;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  resize: none;
  height: 100%;
  &::placeholder {
    color: $whiteBlue;
  }

  &:disabled {
    background: $white-100;
  }

  &:read-only {
    cursor: default;
  }
}

.otherContract {
  p {
    color: $whiteBlue;
    margin-bottom: 0;
  }
}