$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

$secondary: #1d293f;

.typography {
	&.subtitle {
		font-size: 24px;
		line-height: 30px;
		color: $secondary;
	}
}

h1.typography {
	font-family: Montserrat Alternates, sans-serif;
	font-weight: bold;
	font-size: 64px;
	line-height: 100%;
	color: $secondary;
	margin: 32px 0;
	align-items: center;
	word-wrap: break-word; /* Добавляем свойство word-wrap */
	@media (max-width: 320px) {
		Title[level="1"] {
			level: "2";
		}
	}
}
h2.typography {
	font-weight: bold;
	font-size: 56px;
	line-height: 64px;
	color: $secondary;
	margin: 32px 0px;
}

h3.typography {
	font-family: "Inter Bold", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 110%;
	color: $secondary;
	margin: 0;
}
