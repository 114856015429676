$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.formats {
	color: $whiteBlue;
	font-size: 16px;
	margin-top: 11px;
  margin-bottom: 0;
}

.button {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: $green;
	font-weight: $bold-weight;
	text-decoration: underline;

	img {
		margin-right: 11px;
	}
}

.label {
	color: $grey-100;
	margin-bottom: 12px;
}

.error {
  font-weight: 700;
  color: #E65332;
}

.upload {
	display: flex;
	align-items: center;
	grid-column-gap: 11px;
	border: 2px dashed $whiteBlue;
	border-radius: 8px;
	padding: 16px;
	height: max-content;
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		opacity: 0.7;
	}
}

.image {
	width: 110px;
	height: 103px;
	border-radius: 8px;
  object-fit: cover;
}
