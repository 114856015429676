$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.modal {
	max-width: 812px;
	width: 90%;
}

.headerButtons { 
	display: flex;
	justify-content: flex-end;
}

.icon {
	width: 20px;
	height: 20px;

	&Close {
		@extend .icon;
		fill: #5B6880;
	}
}

.modal_content {
    padding: 32px;
    background-color: white;
}

.modal_content_header {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    color: #5b6880;
}

.modal_content_notifType {
    margin-top: 23px;
    font-size: 18px;
    font-weight: 500;
    line-height: 110%;
    color: #1d293f;
}

.modal_content_notif_date {
    font-size: 16px;
    font-weight: 100;
    line-height: 140%;
    color: #aab8d1;
}

.modal_content_notif_content {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 100;
    line-height: 140%;
    color: #5b6880;
    white-space: pre-line;
    overflow-y: auto;
    max-height: 90vh;
    b {
        font-weight: bold;
    }
}

.modal_content_footer {
    margin-top: 62px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.modal_content_footer > div {
    height: 40px;
    background-color: #17b67c;
    border-radius: 2px;
    text-align: center;
}

.modal_content_footer > div > span {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    line-height: 40px;
    font-size: 16px;
    color: white;
    line-height: 110%;
    font-weight: 100;
}

.modal_content_footer > div:first-child {
    width: 230px;
    min-width: 230px;
}

.modal_content_footer div:last-child {
    margin-left: 20px;
    width: 140px;
    max-width: 140px;
}

.modal_button:hover {
    cursor: pointer;
}

.m_button {
    width: max-content !important;
    padding: 0 35px;
    margin-left: 20px;
    min-width: max-content !important;
    &:hover {
        cursor: pointer;
    }
}

 .ant-modal-body {
    padding: 0 !important;
}
.cross {
    cursor: pointer;
    img {
        width: 20px;
    }
}