$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.title {
	font-weight: $bold-weight;
	font-size: 24px;
}

.h2title {
	font-weight: $bold-weight;
	font-size: 22px;
}

.h3title {
	font-weight: $bold-weight;
	font-size: 20px;
}

.h4title {
	font-weight: $bold-weight;
	font-size: 18px;
	line-height: 28px;
	text-align: justify;
	text-indent: 40px;
}

.h5title {
	font-weight: $regular-weight;
	font-size: 16px;
}

.h6title {
	font-weight: $bold-weight;
	font-size: 13px;
}

.defaultColor {
	color: $grey-100;
}

.blackColor {
	color: $black;
}

.redColor {
	color: $red;
}

.greenColor {
	color: $green
}

.whiteColor {
	color: $white;
}

.yellowColor {
	color: $yellow;
}

.blueColor {
	color: $blue;
}

.darkBlue {
	color: $darkBlue;
}

.defaultBackgroundColor {
	background: $white;
}

.grayBackgroundColor {
	background: $grey;
}

.lgrayBackgroundColor {
	background: $light-grey;
}

.redBackgroundColor {
	background: $red;
}

.greenBackgroundColor {
	background: $green;
}

.yellowBackgroundColor {
	background: $yellow;
}

.blueBackgroundColor {
	background: $blue;
}

.notBackgroundColor {
	background: transparent;
}

@media (max-width: 800px) {
	.lgrayBackgroundColor {
		background: $white;
	}
}