$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.editor__wrapper {
  border: 1px solid $whiteBlue;
  border-radius: 14px;
  padding: 12px;
  cursor: default;
  color: $darkBlue;
}

.editor__subwrapper {
  max-height: 729px;
  overflow-y: auto;
  text-align: justify;

  &::-webkit-scrollbar {
    width: 3px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $grey-100;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.dialog {
  min-width: 420px;
}

.buttons__wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 50px 0 30px;
}

.dialog__buttons__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reset__button__wrapper {
  margin-bottom: 20px;
}

.reset__button {
  font-size: 16px !important;
}

.button__save {
  margin-right: 30px !important;
  margin-left: 30px !important;
  padding: 5px 15px !important;
  border: 1px solid $green !important;
  font-family: 'Inter Regular', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 130% !important;
  color: $green !important;
}

input::-webkit-input-placeholder {
  color: $green;
}

input:-moz-placeholder {
  color: $green;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $green;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: $green;
}
[contentEditable=true][aria-label]:empty:before {
  content: attr(aria-label);
}
[contentEditable=true][aria-label] {
  color: $green;
   * {
    all: unset !important;
    color: $green !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    width: unset !important;
    height: unset !important;
    display: inline !important;
  }
}

.messageStyle {
  margin-top: 29px;
  ul {
    li:nth-child(even) {
        background-color: $white-200;
    }
  }
}