$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.searchRow {
  background-color: #E7EEF8;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 30px 12px 16px;
  width: 650px;
}

.searchField {
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
  max-width: 700px;
  margin-right: 1rem;

  &__left {
    display: flex;
    flex-grow: 1;
  }

  &__button {
    color: #5B6880;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .searchField {
    margin-right: 0;
  }
}