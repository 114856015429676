$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.field {
  font-weight: $bold-weight;
  color: $darkBlue;
  width: 273px;
  height: 16px;
  margin-right: 32px;
  margin-bottom: 5px;
}

.phone__field__label {
  font-size: 14px;
  font-weight: $bold-weight;
  color: $darkBlue;
  line-height: 13px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 13px;
}
.phone__field {
  border: 1px solid #d3dff0;
  padding: 6px 20px;
  border-radius: 4px;

  input,
  textarea {
    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0;
      text-align: left;
      color: #5B6880;
    }
  }
}

.phone__field::before {
  display: none;
}

.phone__field::after {
  display: none;
}

.error {
  color: #E65332
}

.otherConditions {
  margin-top: 96px;
  margin-bottom: 32px;
  font-weight: $bold-weight;
  color: $darkBlue;
}