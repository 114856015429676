$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.popup {
  visibility: visible;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  width: 428px;
  height: auto;
  margin-top: 10px;
  padding: 20px;
  background-color: $white;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.3s linear;
  z-index: 150;
}

.popupLink {
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: $white-200;
  }
}

.logo {
  margin-right: 16px;
}

.text {
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #111827;
  }
  p {
    height: 40px;
    line-height: 20px;
    color: $grey-500;
    &:hover {
      color: $green;
    }
  }
}