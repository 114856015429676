$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.page {
  padding-bottom: 86px;
  font-size: 18px;
  margin-top: 35px;
  p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
  }
  a {
    font-size: 18px;
    line-height: 28px;
    color: $green;
  }
  .pTop {
    margin-top: 22.61px;
  }
  .tabPane {
    padding-bottom: 5px;
    padding-top: 49px;
  }
}

.tableTitleCell {
  width: 394px;
  color: $darkBlue;
  margin-bottom: 4px;
}

.tableInfoCell {
  color: $grey-100;
  border-bottom: 1px solid #CDDEED;
}

.dataClass {
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: $regular-weight !important;
}

.note {
  display: flex;
  padding: 28px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid $green;
  font-size: 16px;
  color: $grey-100;
  margin-top: 30px;
  font-weight: 400;
  line-height: 110%;
  align-self: stretch;
}
.noteTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  color: $green;
  font-family: Inter, sans-serif;
  font-style: normal;
}


@media (max-width: 1000px) {
  .tableTitleCell {
    width: 100%;
  }
  .note {
    text-align: justify;
  }
}