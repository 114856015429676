$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.sidebar {
  background: $darkBlue;
  width: 245px;
  height: 100%;
  min-height: 100vh;
  padding: 32px 20px;
}

.navigation {
  margin-top: 57px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.navLinkActive {
  color: $green !important;

  svg,
  path {
    fill: $green;
  }
}

.logo {

  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 135px;
  }
}

.item {
  display: flex;
  align-items: center;
  color: white;
  padding-bottom: 10px;
  margin-top: 10px;

  p {
    margin: 0px;
    font-weight: $middle-weight;
    font-size: 12px;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__arrow {
    float: right;
    color: white;
    margin: 0px;
  }

  &__group {
    justify-content: space-between;
  }

  &__children {
    margin-left: 30px;
  }

  svg {
    margin-right: 10px;
  }


  &:link {
    color: $white;
  }

/*  &:last-child {
    cursor: pointer;

    p {
      color: $whiteBlue;
    }
  }*/

  &__disabled {
    pointer-events: none;
    color: #5B6880 !important;

    svg {
      fill: #5B6880;
    }
  }
}

.spanLogout {
	cursor: pointer;
	display: flex;
	justify-content: left;
    align-items: center;
	color: #AAB8D1;
	padding-bottom: 16px;
	margin-top: 10px;
	text-align: center;

    svg {
      margin-right: 10px;
      color: #AAB8D1;
    }

	p {
        margin: 0px;
        color: #AAB8D1;
		font-size: 12px;
	}

	&:hover {
		color: $green !important;

		svg,
		path {
			fill: $green;
		}

		p {
			color: $green;
		}
	}
}

.arrow {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  &__opened {
    transform: rotate(180deg);
  }
}

.notImplemented {
  cursor: pointer;

  &:hover {
    color: $green !important;

    svg, path {
      fill: $green;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
