$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $light-grey;
  height: max-content;
  padding: 23px 39px 17px 37px;
}

.header > div:last-child {
  display: flex;
  gap: 20px;
}

.leftSide {
  display: flex;
  align-items: center;
  gap: 16px;
}

.title {
  font-style: normal;
  font-weight: $bold-weight;
  font-size: clamp(18px, 4vw, 32px);
  line-height: 40px;
  color: $darkBlue;
  margin-bottom: 0;
}

.rightSide {
  align-items: center;

  & ul {
    margin: 0;
  }
}

.settings {
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-column-gap: 46px;
}

.designedBy {
  font-weight: $middle-weight;
}

.breakWord {
  display: flex;
  font-weight: $middle-weight;
  // width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exit {
  font-size: 16px;
  cursor: pointer;

  img {
    display: none;
    width: 33px;
  }
}

.hamburger {
  max-width: 40px;
  display: none;
  cursor: pointer;
}

.headerIcon {
  fill: #5B687F;
  &:hover {
    fill: $darkGrey;
  }
}

.popupToggle {
  cursor: pointer;
}

.hideMobile {
  p {
   margin-bottom: 0;
   color: #5B687F !important;
  }
}

@media (max-width: 1180px) {
  .changeProfile {
    display: none;
  }

  .settings {
    grid-column-gap: 25px;
    grid-template-columns: repeat(3, max-content);
  }
}

@media (max-width: 1000px) {

  .header {
    padding: 16px 20px;
  }

  .exit {
    span {
      display: none;
    }

    img {
      display: block;
    }
  }

  .hideMobile {
    display: none;
  }

  .settings {
    grid-template-columns: 1fr;
  }

  .designedBy {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .breakWord {
    display: none;
  }
}
