$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

label {
  margin-outside: 10px;
  color: rgba(29, 41, 63, 1);
}

.radio {
  position: absolute;
  z-index: 0;
  opacity: 0;
}

.radio + label {
  position: relative;
  padding: 0 0 35px 35px;
  cursor: pointer;
}

.radio + label:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 3px;
  width: 14px;
  height: 14px;
  border: 1px solid #aab8d1;
  border-radius: 50%;
  background: #fff;
}

.radio + label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-image: url(../../assets/entrance/componentButton.svg);
  opacity: 0;
  transition: 0.2s;
}

.radio:checked + label:after {
  opacity: 1;
}

@media (max-width: 1000px) {

  .radio + label {
    padding: 0 0 20px 20px;
  }

  .radio + label:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 1px;
    width: 14px;
    height: 14px;
    border: 1px solid #aab8d1;
    border-radius: 50%;
    background: #fff;
  }

  .radio + label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-image: url(../../assets/entrance/componentButton.svg);
    opacity: 0;
    transition: 0.2s;
  }
}