.inputFieldError {
    width: calc(100% - 0px);
    max-width: 439px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(230, 83, 50, 1);
    padding-left: 16px;
    color: rgba(29, 41, 63, 1);
    margin-bottom: 4px;
    box-sizing: border-box;
}
.inputField {
    width: calc(100% - 0px);
    max-width: 439px;
    height: 40px;
    margin-bottom: 16px;
    border-radius: 4px;
    background: #F9FBFF;
    border: 1px solid #D3DFF0;
    padding-left: 14px;
    color: rgba(29, 41, 63, 1);
    outline-color: rgba(29, 41, 63, 1);
    box-sizing: border-box;
}
.inputField::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    color: #AAB8D1;
}
.errorMessage {
    max-width: 439px;
    color: red;
    position: relative;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 10px;
    letter-spacing: 0;
    text-align: right;
}