$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.mainInformation {
  display: flex;  
  gap: 32px;
  margin-bottom: 38px;
}

.information {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 64px 32px;
}

.mainInfomationCol {
  flex: 1;
}

.logoCol {
  flex: 1;
  margin-top: 50px;
}

.label {
  font-weight: $bold-weight;
  margin-bottom: 5px;
}

.logoImage {
	max-height: 110px;
  max-width: 400px;
	border-radius: 8px;
  object-fit: cover;
}

.logoFrame {
    border: 2px dashed $whiteBlue;
    border-radius: 8px;
    padding: 16px;
    width: fit-content;
}

@media (max-width: 1000px) {

  .mainInformation {
    flex-direction: column-reverse;
    gap: 25px;
    margin-bottom: 25px;
  }

  .information {
    grid-template-columns: 1fr;
    gap: 25px;
  }

  .logoCol {
    margin-top: 0;
  }

  .employerInfo {
    grid-row-start: 3;
  }

  .companyInfo {
    grid-row-start: 2;
  }

  .logoImage {
    max-width: 100%;
  }

}