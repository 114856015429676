$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.item {
	display: grid;
}

.information {
	display: grid;
	margin-top: 32px;
	grid-row-gap: 8px;
}

.subTitle {
	color: $grey-400;
	font-size: 18px;
	font-weight: $middle-weight;
	margin-top: 24px;
}

.customClass {
  color: $darkBlue;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 19.8px;
  text-align: left;
}

.hideMargin {
	margin-top: 0;
}

.row {
    display: flex;
    gap: 15px;
}

.dataCell {
    flex: 1;
    color: $grey-100;
    font-weight: $regular-weight;
    margin-bottom: 4px;
    white-space: pre-line;
}

.items {
    display: flex;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    flex-wrap: wrap;
    // justify-content: space-between;
}

.action {
    margin-left: 32px;
}

.width-400 {
  width: 415px;
}

.hintIcon {
  fill: #17b67c;
  margin-left: 8px;
  margin-bottom: 6px;
}

.fullWidth {
  width: 100% !important;
}

@media (max-width: 1000px) {
    .row {
        flex-direction: column;
        gap: 0;
        margin-bottom: 15px;
    }

    .dataCell {
      white-space: normal;
    }
}