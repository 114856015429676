$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  width: 1100px;
  background: $white;
  height: 80vh;
  padding: 0 30px;
  overflow-y: scroll;
  margin-top: 20px;
}

.mainInformation {
  display: flex;
  justify-content: space-between;
}

.id__wrapper {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: $grey-400;
}

.date__wrapper {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $grey-100;
}

.editor__wrapper {
  border: 1px solid $whiteBlue;
  border-radius: 14px;
  padding: 12px;
  cursor: default;
  color: $darkBlue;
  margin-top: 15px;
}

.editor__subwrapper {
  max-height: 729px;
  overflow-y: auto;
}

.buttons__wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 50px 0 30px;

  .button {
    font-family: 'Inter, Regular', sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 110% !important;
    color: $darkBlue !important;
    text-transform: none !important;
    padding: 5px !important;
  }
  .reset__button {
    color: $whiteBlue !important;
  }

  .button__save {
    margin-right: 30px !important;
    margin-left: 30px !important;
    padding: 5px 15px !important;
    border: 1px solid $green !important;
    font-family: 'Inter Regular', sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 130% !important;
    color: $green !important;
  }
}

.buttons__wrapper_employer {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 24px;
}

.buttons__wrapper_sign {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 24px;

  .button {
    font-family: 'Inter, Regular', sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 110% !important;
    color: $darkBlue !important;
    text-transform: none !important;
    padding: 5px !important;
  }
  .reset__button {
    color: $whiteBlue !important;
  }

  .button__save {
    margin-right: 30px !important;
    margin-left: 30px !important;
    padding: 5px 15px !important;
    border: 1px solid $green !important;
    font-family: 'Inter Regular', sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 130% !important;
    color: $green !important;
  }
}

.status__wrapper {
  color: $whiteBlue;
  span {
    font-size: 18px;
  }
}

.decline__button {
  margin-right: 30px;
}

.employee__comment__wrapper {
  margin-top: 31px;
}

.ecd__title {
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: $grey-100;
}

.employee__comment {
  border: 1px solid $whiteBlue;
  border-radius: 14px;
  margin-top: 15px;
  padding: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: $darkBlue;
}

.edit__icon {
  width: 22px;
  height: 22px;
}

.button_etd {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 130% !important;
  color: #5B6880 !important;
  padding: 0 !important;
  text-transform: none !important;
}

.button__disabled {
  opacity: 0.5 !important;
}

.buttonEnd {
  margin: 20px 0 20px 20px;
  float: right !important;
}