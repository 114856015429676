$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.empty_message {
  color: $grey-100;
  margin: 32px 0;
  font-size: 18px;
  line-height: 110%;
}

.modal {
  &__wrapper {
    padding: 32px;
    width: 85vw;
    height: 80vh;
    overflow-y: scroll;
  }
  &__title {
    color: $darkBlue;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 32px;
  }
  &__sub__title {
    color: #5B6880;
    margin-top: 8px;
    margin-bottom: 64px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
  &__table {
    padding: 32px;
    max-width: 1216px;
  }
}


.wrapper {
  margin: 20px 27px 37px 0;
}

.title__info__branch {
  color: $grey-100;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
}

.success__modal {
  &__wrapper {
    padding: 40px;
    padding-bottom: 60px;
    text-align: center;
    width: 440px;
  }
  &__title {
    color: $darkBlue;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 16px;
  }
  &__subtitle {
    margin-bottom: 16px;
    font-size: 16px;
    color: $grey-100;
  }
  &__text {
    margin-bottom: 32px;
    font-size: 16px;
    color: $grey-100;
  }
}

.filter {
  background-color: $grey-300;
  padding: 18px 16px 20px;
  border-radius: 4px;
  margin-top: 40px;
  margin-bottom: 32px;

  &__item {
    cursor: pointer;
    margin-right: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: $grey-100;
    padding-bottom: 5px;
    &__active {
      border-bottom: 1px solid $green;
    }
  }
}

.table__head__row {
	background-color: #5B6880;
	color: white !important;
	span {
		color: white !important;
	}
	svg {
		color: white !important;
	}
}

.table__container {
	border-radius: 8px 8px 0px 0px;
	border: 1px solid $light-grey-200;
  overflow-x: unset !important;
}

.checkbox {
	&__head {
		&__root {
			color: $white !important;
		}
	}
	
	&__checked {
		color: $green !important;
	}
}

.reset__button { color: $whiteBlue }
.reset__button__active { color: $green }

.empty__table__plug {
	font-size: 24px;
	line-height: 130%;
	color: $grey-100;
	text-align: center;
	border: 1px solid $light-grey-200;
	border-top: none;
	padding: 80px 0;
}

.buttons__wrapper {
	margin-top: 45px;
}

.branch__info__wrapper {
  margin-bottom: 37px;
}

.checkbox {
	&__head {
		&__root {
			color: $white !important;
		}
	}
	
	&__checked {
		color: $green !important;
	}
}

.button__view {
  margin-right: 16px;
  cursor: pointer;
}

.reject__title {
  color: #EB5757;
}

.icon {
  width: 20px;
  margin-right: 10px;
}

.menu__item {
  cursor: pointer;
}

.table__row {
  cursor: pointer;
}

@media (max-width: 1210px) {

}

.cross {
  cursor: pointer;
  img {
    width: 20px;
  }
}