$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

@import 'src/features/contracts/additionalContracts/AdditionalContractStyles.module';

.header {
  display: flex;
  justify-content: space-between;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: $darkBlue
  }
}

.button {
  display: inline-block;
  min-width: 245px;
  padding: 11px 22px;
  margin-left: 25px;
  border: none;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  color: #FFFFFF;
  background-color: #17B67C;
}

.textStatusWrapper {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  text-align: left;
}

.buttonMargin {
  margin-right: 25px;
}

.headerWrapper {
  margin-bottom: 50px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 90px;
}

.textGray {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #5B6880;
}

.textDataEmpoyy {
  margin: 0;
  padding: 0;
}

.textEmployyGray {
  margin-left: 30px;
  font-size: 14px;
  line-height: 16px;
  color: #5B6880;
}

.textSpanId {
  text-transform: uppercase;
}

.containerData {
  display: flex;
  justify-content: space-between;
}

.containerDataItem {
  width: 50%;
}

.containerDataItem:first-child {
  margin-right: 24px;
}

.wrapperDescription {
  display: flex;
}

.textNameEmploer {
  padding: 0;
  margin: 0;
  padding-top: 5px;
  padding-left: 83px;
  display: block;
  width: 452px;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5B6880;
}

.wrapperText {
  display: flex;
}

.wrapperList {
  margin-right: 17px;
}

.textTerm {
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
}

.tableWrapper {
  margin-top: 2rem;
  margin-bottom: 65px;
}

.textStatusName {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.textRequestStatus {
  display: inline-block;
  margin-left: 20px;
  color: #5B6880;
  line-height: 21px;
}

.textDateSign {
  display: inline-block;
  margin-left: 32px;
  color: #5B6880;
  line-height: 21px;
}

.textDateError {
  display: inline-block;
  margin-left: 36px;
  color: #5B6880;
  line-height: 21px;
}

.textError {
  display: inline-block;
  margin-left: 72px;
  color: #5B6880;
  line-height: 21px;
}

.warning {
  color: #EB5757;
  margin-top: 0.5rem;

  img {
    margin-right: 0.5rem;
  }
}

.tableTitleCell {
  min-width: 180px;
  width: auto;
}
