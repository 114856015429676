$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;

  .gridWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
  }

  .gridTwoWrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 30px;
  }

  .gridTwoReversWrapper {
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    grid-column-gap: 30px;
  }

  .selectCol {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 5px;
  }
  .button {
    width: 130px;
    align-items: center;

    &Red {
      @extend .button;
      color: #EB5757;
    }
  }
  .icon {

    width: 12px;
    height: 12px;
    margin-right: 8px;
    &Green {
      @extend .icon;
      fill: $green;
    }

    &Red {
      @extend .icon;
      fill: #EB5757;
    }
  }
}

.mTop {
  margin-top: 30px;
}

.mTop5 {
  margin-top: 5px;
}

.mLeft {
  margin-left: 15px;
}

.mBottom {
  margin-bottom: 24px;
}

.mBottom5 {
  margin-bottom:5px;
}

.social {
  margin-right: 0;
  font-weight: $bold-weight;
  color: $darkBlue;
}