$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.modal {
  max-width: 1216px;
  width: 90vw;
  max-height: 85vh ;
  overflow-y: auto;
}

.wrapper {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  @media (max-width: 1350px) {
    grid-template-columns: 3fr 4fr;
  }

  @media (max-width: 1210px) {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  }
}

.downloadButton {
  position: absolute;
  right: 40px;
}

.form {
  display: grid;
  grid-row-gap: 15px;
}

.p10 {
  padding: 10px;
}

.functions {

  &_container {
    width: 100%;
    padding: 10px;
    background-color: #F9FBFF;
    border: 1px solid #D3DFF0;
    border-radius: 4px;
    gap: 8px;
    display: inline-flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #AAB8D1;
      padding: 4px 8px;
      border: 1px solid #cbd5e1;
      border-radius: 4px;
      font-family: Inter, serif;

      &_content {
        color: white;
        font-size: 10px !important;
        font-family: Inter, serif;
        font-weight: 400;
        line-height: 11px;
        word-wrap: break-word;
        flex-grow: 1;
        padding-right: 10px;

      }
    }

    button {
      color: white;
      border: none;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      cursor: pointer;
      font-size: 18px;
      line-height: 24px;
      text-align: center;

      &:hover {
        color: #3e484f;
      }
    }

    p {
      font-size: 10px;
    }
  }
}

.buttons {
  margin: 25px 0;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.result {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;
  display: inline-flex;

  &_row {
    display: inline-flex;
    gap: 10px;

    p {
      margin: 0;
      font-size: 14px;
      font-family: "Inter Medium", serif;
      font-weight: 500;
      line-height: 15.40px;
    }
  }

  h2 {
    margin-top: -10px !important;
    margin-bottom: 10px !important;
  }
}

.grading {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  text-align: right;
  &_label {
    color: #AAB8D1;
    font-size: 15px;
    font-family: "Inter Medium", sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
  }

  &_item {
    color: #AAB8D1; font-size: 20px;
    font-family: "Inter Medium", sans-serif;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
  }
}

