$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  display: none;
  align-items: center;
  @media (max-width: 1210px) {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    background: $white-200;
  }

  .greenBtn {
    margin: 8px 8px 12px 12px;
    transition: 0.2s;
    border-radius: 8px;
    a:hover,
    button:hover {
      transition: 0.2s;
      color: $green;
      background: $darkBlue;

    }
    a,
    button {
      cursor: pointer;
      border: none;
      outline: none;
      background: $green;
      color: #fff;
      text-decoration: none;
      padding: 2px 4px;
      border-radius: 8px;
      display: inline-block;
    }
  }

  .closeIcon {
    color: #AAB8D1;
    width: 10px;
    height: 10px;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .icon {
    width: 30px;
    height: 30px;
  }
  .text {
    margin-left: 10px;
   p {
     margin-bottom: 0;
   }
  }
  .available {
    font-size: 12px;
  }
  .close {
    display: flex;
    margin-left: 10px;
  }
}