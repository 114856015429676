$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$light-grey-200: #d3dff0;
$yellow: #ffd500;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.modal {
  padding: 32px;
  max-width: 1216px;
  width: 90vw;
  display: block;
  margin-bottom: 50px;
  margin-top: 50px;
  margin: 100px auto;
}

.header {
  display: flex;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 10%;
    color: $darkBlue
  }
}

//.wrapper {
//  padding-right: 16px;
//  overflow-y: auto;
//  max-height: 80vh;
//  padding-top: 10px;
//  padding-bottom: 10px;
//}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 200%;
  color: $darkBlue;
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.container {
  display: flex;
  gap: 40px;
}

.name {
  font-weight: $bold-weight;
  color: $darkBlue;
}

.titles {
  margin-right: 20px
}

.values {
  color: #5b6880
}

.flexBlock {
  color: #5b6880;
  display: inline-flex;
  gap: 20px;
}

.updateButton {
  cursor: pointer;
  color: #17b67c;
  align-items: center;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.refreshIcon {
  fill: #17b67c;
  margin-right: .5rem;
}

.hintIcon {
  fill: #17b67c;
  margin-left: 8px;
}

.information {
  margin-bottom: 24px;
  gap: 20px;
  div {
    width: 33%;
    span {
      color: #eb5757;
      position: absolute;
      bottom: -25px;
    }
  }
}

.mb20 {
  margin-bottom: 20px;
}

.button {
  height: 46px;
  margin: auto 0;
  min-width: max-content;
}

.table {
  tr {
    td:first-child {
      font-weight: $bold-weight;
      color: $darkBlue;
      padding-right: 15px;
      display: block;
      min-width: 225px;
    }
  }
}

.mask {
  position: relative;
  label {
    position: absolute;
    top: -25px;
  }
  div {
    width: 100%;
    padding: unset;
    input {
      padding: 10px 20px;
      &::placeholder {
        color: #7786bb;
      }
      &:disabled {
        background: $white-100;
      }
    }
  }
}

.errorColor {
  color: #eb5757;
  position: absolute;
}


.phone__field {
  border: 1px solid #d3dff0;
  padding: 6px 20px;
  border-radius: 4px;
}

.phone__field::before {
  display: none;
}

.phone__field::after {
  display: none;
}

.buttonLink {
  text-transform: unset !important;;
}

.checkbox {
  &__head {
    &__root {
      color: $white !important;
    }
  }

  &__checked {
    color: $green !important;
  }
}

.flexAuto {
  flex: auto;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  float: left;
  margin-right: 5px;
}
.capitalize::first-letter {
  text-transform: capitalize;
}